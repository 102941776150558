import React from "react";
import dateFormat from "dateformat";
import moment from "moment-timezone";
import ReactGA from "react-ga";
import { addDays, parseISO, format } from "date-fns";
import { sortBy } from "lodash";
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";
ReactGA.initialize("UA-177866127-1");

moment.tz.setDefault("GMT");
export const ReactGAWrapper = ReactGA;
export const RESERVATION_REPORT = "RESERVATION_REPORT";
export const SOURCE_COMMISSION_MANAGEMENT = "SOURCE_COMMISSION_MANAGEMENT";
export const PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT";
export const CACHE_LIVE_STATUS = "CACHE_LIVE_STATUS";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const RATEPLAN_MANAGEMENT = "RATEPLAN_MANAGEMENT";
export const EDT_TIMEZONE_STRING = "America/New_York";
export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const CRS_DETAILS = {
  1: "Pegasus",
  2: "TravelClick",
  3: "Synxis",
  4: "Offline",
  5: "SHR",
  6: "Hilton",
  8: "Mews",
  9: "Dingus",
  10: "D-Edge",
  11: "Opera"
};

export const CRS_ID = {
  Pegasus: 1,
  TravelClick: 2,
  Synxis: 3,
  Offline: 4,
  SHR: 5,
  Hilton: 6,
  Mews: 8,
  Dingus: 9,
  DEDGE: 10,
  OPERA: 11
};

export const HOPPER_MARKETPLACE = "Hopper_Marketplace";

export const addTwoDecimals = (one, two) => (+one * 100 + +two * 100) / 100;

export const addMultipleDecimals = (...args) =>
  args.reduce((acc, curr) => +acc + +curr * 100, 0) / 100;
  
export const getRoundedToTwoDecimals = ({ value }) => {
  const roundedValue = new Intl.NumberFormat("en-US", {
    // style: "currency",
    // currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
  return roundedValue;
};

export const getRounded = ({ value }) => new Intl.NumberFormat("en-US").format(value);

export const getRoundedToMinTwoDecimals = number => {
  const decimalCount = number && (number.toString().split(".")[1] || "").length;
  if (decimalCount && decimalCount < 2) {
    return getRoundedToTwoDecimals({ value: number });
  }
  return number;
};

export const getRoundToTwoDecimals = (value, noOfDecimals = 2) =>
  Math.round(value * Math.pow(10, noOfDecimals)) / Math.pow(10, noOfDecimals);

export function parseCurrency(value) {
  if (isNaN(value)) return value;
  return getRoundedToTwoDecimals({ value });
}

export const getFormattedDate = (value, format = "yyyy-mm-dd") => {
  if (!!value)
    try {
      const dateStr = dateFormat(moment(value), format);
      return dateStr;
    } catch (error) {
      console.log("invalid date: " + value);
    }
  return "NA";
};

export const getFormattedDateInEDTWithFormat = (value, formatStr = "YYYY-MM-DD") => {
  if (value) {
    const dateStr = moment(value)
      .tz(EDT_TIMEZONE_STRING)
      .format(formatStr);
    return dateStr;
  }
  return "";
};

export const getFormattedLocalDate = (value, timezone) => {
  if (value) {
    const dateStr = moment(value)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm z");
    return dateStr;
  }
  return "";
};

export const getFormattedDateByTimeZone = (value, timezone) => {
  if (value) {
    const dateStr = moment(value)
      .tz(timezone)
      .format("YYYY-MM-DD");
    return dateStr;
  }
  return "";
};

export const convertDateFromOneFormatToOther = (dateStr, formatFrom, formatTo) =>
  moment(dateStr, formatFrom).format(formatTo);

export const getFormattedDateTime = value =>
  (value &&
    moment(value)
      .tz(EDT_TIMEZONE_STRING)
      .format("YYYY-MM-DD HH:mm:ss")) ||
  "";

export const getFormattedDBDate = value => (value && moment(value).format("YYYY-MM-DD")) || "";

export const getFormattedDateTimeSSS = value =>
  (value &&
    moment(value)
      .tz(EDT_TIMEZONE_STRING)
      .format("YYYY-MM-DD HH:mm:ss.SSS")) ||
  "";

export const getFormattedDateTimeHHmm = value =>
  (value && moment(value).format("YYYY-MM-DD HH:mm")) || "";

export const getFormattedDateTimeWithTimezone = value =>
  (value &&
    moment(value)
      .tz(EDT_TIMEZONE_STRING)
      .format("YYYY-MM-DD HH:mm z")) ||
  "";

export const getFormattedUTCDateTime = (value, format) =>
  (value && dateFormat(moment(value), `UTC:${format || "yyyy-mm-dd HH:MM:ss"}`)) || "";

export const getFormattedTime = value =>
  (value &&
    moment(value)
      .tz(EDT_TIMEZONE_STRING)
      .format("HH:mm:ss")) ||
  "";

export const getFormattedDateInMonthDayYear = value => moment(value).format("MMM Do YY");

export function validateNumber(numberInput) {
  numberInput = numberInput.replace(/[^0-9.]/g, "");

  return numberInput.replace(/(\..*)\./g, "$1");
}

export const renderEllipsisText = (text, ellipsisLength) =>
  text ? <EllipsisText text={text} length={ellipsisLength} /> : null;

//constants
export const invoicableColumns = [
  "companyCode",
  "propertyName",
  "rateCode",
  "otaConfirmationCode",
  "cancelled",
  "guestName",
  "room",
  "arrival",
  "departure",
  "leadTime",
  "htNetPrice",
  "htTax",
  "htGrossPrice",
  "clientSalePrice",
  "taxes",
  "grossPrice",
  "policyCode",
  "cancellationDate",
  "cancelCutOffDate",
  "timezoneId",
  "cancellationAmount"
];

export const demandClientColumns = [
  "confirmationNumber",
  "companyCode",
  "otaClientName",
  "propertyName",
  "cancelled",
  "rateCode",
  "parentRatePlanCode",
  "isBreakfastIncluded",
  "otaConfirmationCode",
  "sourceConfirmationNumber",
  "pmsConfirmationNumber",
  "leadTime",
  "bookingDateDuplicate",
  "bookingDate",
  "arrival",
  "departure",
  "los",
  "guestName",
  "noOfAdults",
  "room",
  "invoiceCurrency",
  "receivableNetPrice",
  "receivableTax",
  "receivableGrossPrice",
  "clientSalePrice",
  "taxes",
  "grossPrice",
  "refNRef",
  "policyCode",
  "cancelCutOffDate",
  "timezoneId",
  "cxlCurrency",
  "cancellationAmount",
  "cancellationDate"
];

export const hotelDiscrepancyColumns = [
  "confirmationNumber",
  "propertyName",
  "propertyCode",
  "cancelled",
  "rateCode",
  "parentRatePlanCode",
  "sourceConfirmationNumber",
  "pmsConfirmationNumber",
  "isBreakfastIncluded",
  "crsCancellationCode",
  "relocated",
  "leadTime",
  "bookingDateDuplicate",
  "bookingDate",
  "arrival",
  "departure",
  "los",
  "guestName",
  "noOfAdults",
  "room",
  "payableCurrency",
  "payableNetPrice",
  "payableTax",
  "payableGrossPrice",
  "refNRef",
  "policyCode",
  "timezoneId",
  "cancellationDate"
];

export const breakageReportColumns = [
  "confirmationNumber",
  "companyCode",
  "otaClientName",
  "propertyName",
  "propertyCode",
  "cancelled",
  "rateCode",
  "parentRatePlanCode",
  "sourceConfirmationNumber",
  "pmsConfirmationNumber",
  "crsCancellationCode",
  "otaConfirmationCode",
  "relocated",
  "arrival",
  "departure",
  "los",
  "guestName",
  "noOfAdults",
  "room",
  "billingType",
  "vccProvider",
  "lastTransactionType",
  "lastTransactionAmount",
  "lastTransactionStatus",
  "lastTransactionDate",
  "fundsChargedCurrency",
  "amountSettled",
  "vccBreakage",
  "dbPaidCurrency",
  "dbPaidAmount",
  "dbPaidDate",
  "dbBreakage",
  "payableCurrency",
  "payableNetPrice",
  "payableTax",
  "payableGrossPrice",
  "htCommissionPercent",
  "htFeesCurrency",
  "fees",
  "clientCommissionAmount",
  "paid",
  "clientPaidAmount",
  "invoiceCurrency",
  "receivableNetPrice",
  "receivableTax",
  "receivableGrossPrice",
  "clientSalePrice",
  "taxes",
  "grossPrice",
  "refNRef",
  "policyCode",
  "cancelCutOffDate",
  "timezoneId",
  "cxlCurrency",
  "cancellationAmount",
  "cancellationDate",
  "propertyCity",
  "propertyState"
];

export const relocatedReportColumns = [
  "confirmationNumber",
  "companyCode",
  "otaClientName",
  "propertyName",
  "cancelled",
  "rateCode",
  "parentRatePlanCode",
  "isBreakfastIncluded",
  "crsId",
  "sourceConfirmationNumber",
  "pmsConfirmationNumber",
  "crsCancellationCode",
  "otaConfirmationCode",
  "relocated",
  "leadTime",
  "arrival",
  "departure",
  "los",
  "guestName",
  "noOfAdults",
  "room",
  "payableCurrency",
  "payableNetPrice",
  "payableTax",
  "payableGrossPrice",
  "paid",
  "clientPaidAmount",
  "invoiceCurrency",
  "receivableNetPrice",
  "receivableTax",
  "receivableGrossPrice",
  "clientSalePrice",
  "taxes",
  "grossPrice",
  "refNRef",
  "policyCode",
  "cancelCutOffDate",
  "timezoneId",
  "cxlCurrency",
  "cancellationAmount",
  "cancellationDate"
];

export const CLIENT_HIDDEN_COLUMNS = [
  "companyCode",
  "contractType",
  "otaClientName",
  "relocated",
  // "pmsConfirmationNumber",
  // "bookingDateDuplicate",
  // "bookingDate",
  "parentRatePlanCode",
  "vccProvider",
  "payableCurrency",
  "payableNetPrice",
  "payableTax",
  "payableGrossPrice",
  "amountSettled",
  "amountReconciled",
  "dbPaidAmount",
  "vccBreakage",
  "dbBreakage",
  "dbPaidDate",
  "lastTransactionType",
  "lastTransactionAmount",
  "lastTransactionStatus",
  "lastTransactionDate",
  "fundsChargedCurrency",
  "sourceCostCurrency",
  "cost",
  "costTax",
  "totalCost",
  "crsCancellationCode",
  "crsId",
  "htSalePriceCurrency",
  "htNetPrice",
  "htTax",
  "htGrossPrice",
  "leadTime",
  "cost",
  "paid",
  "costTax",
  "totalCost",
  "htCommissionPercent",
  "htFeesCurrency",
  "fees",
  // "cancellationDate",
  // "cancelCutOffDate",
  // "cancellationAmount",
  "channel",
  "invoicedAmount",
  "buyer",
  "propertyCity",
  "propertyState",
  "ariAudit",
  "ariAuditTest",
  "billingType",
  "dbPaidCurrency",
  "clientPaidAmount",
  "policyCode",
  "swappedRateCode",
  "swappedParentRateCode",
  "swappedIsBreakfastIncluded",
  "swappedCurrencyCode",
  "swappedPayableCost",
  "swappedPayableTax",
  "swappedPayableTotal",
  "swappedHtCommission",
  "swappedCurrencyCode",
  "swappedHtFees",
  "swappedPolicy",
  "swappedRefundable",
  "swappedCancellationCutoffDate",
  "swappedCancellationCutoffDate",
  "swappedCurrencyCode",
  "swappedCancellationAmount",
  "swappedCancellationDate"
];

export const NON_REFUNDABLE_POLICIES = ["9999D-100%", "999D-100%"];

export const REVENUE_REPORT_COLUMNS = [
  "propertyCity",
  "propertyState",
  "companyCode",
  "propertyName",
  "rateCode",
  "mealPlanCode",
  "parentRatePlanCode",
  "cancelled",
  "leadTime",
  "los",
  "bookingDateDuplicate",
  "bookingDate",
  "arrival",
  "departure",
  "noOfGuests",
  "room",
  "totalCost",
  // "htGrossPrice",
  // "htCommissionPercent",
  // "fees",
  "policyCode"
];

export const SUPPLIER_USER_COLUMNS = [
  "confirmationNumber",
  "companyCode",
  "propertyName",
  "rateCode",
  "mealPlanCode",
  "cancelled",
  "leadTime",
  "los",
  "bookingDateDuplicate",
  "bookingDate",
  "arrival",
  "crsCancellationCode",
  "pmsConfirmationNumber",
  "departure",
  "guestName",
  "noOfGuests",
  "room",
  "billingType",
  "currencyCode",
  "payableNetPrice",
  "payableTax",
  "payableGrossPrice",
  "policyCode",
  "cancellationDate",
  "cancelCutOffDate",
  "timezoneId",
  "primaryId"
];

export const DAYRATE_LOOKUP_POTENTIAL_USER_HIDDEN_COLUMNS = [
  "roomCode",
  "sourcePrice",
  "sourceTax",
  "sourceGrossPrice",
  "commission",
  "inventory",
  "maxLos",
  "isOpenToDeparture",
  "inventoryLastUpdated",
  "restrictionLastUpdated",
  "marginConfigured",
  "rateplanStopSell",
  "overrideStopSell",
  "hbStopSell"
];

export const getDateString = date => {
  return dateFormat(date, "isoDate");
};

export const validateEmail = email => {
  if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email)) {
    return true;
  }
  return false;
};

export const newTabLinks = ["Kibana Dashboard"];

export const clean = object => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      clean(v);
    }
    if ((v && typeof v === "object" && !Object.keys(v).length) || v === null || v === undefined) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};

export const sortDataByDesc = ({ dataToSort, dataField, desc }) => {
  dataToSort = sortBy(dataToSort, dataField);
  if (!desc) {
    dataToSort = dataToSort.reverse();
  }
  return dataToSort;
};

export const dayNamesShort = {
  1: "S",
  2: "M",
  3: "T",
  4: "W",
  5: "T",
  6: "F",
  7: "S"
};

export const dayNamesMedium = {
  1: "Sun",
  2: "Mon",
  3: "Tue",
  4: "Wed",
  5: "Thu",
  6: "Fri",
  7: "Sat"
};

export const dayNamesMediumForUI = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const dayNamesForRateplanScreen = ["S", "M", "T", "W", "T", "F", "S"];
export const dowNamesForOverrides = ["M", "T", "W", "T", "F", "S", "S"];

export const dayNamesLong = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday"
};

export const dayNamesValues = {
  Sun: 1,
  Mon: 2,
  Tue: 3,
  Wed: 4,
  Thu: 5,
  Fri: 6,
  Sat: 7
};

export const USER_ROLES = {
  USER: "USER",
  SUPER_USER: "SUPER_USER",
  PROPERTY_ADMIN: "PROPERTY_ADMIN",
  VALIDATION_USER: "VALIDATION_USER",
  POTENTIAL_USER: "POTENTIAL_USER",
  CLIENT_ADMIN: "CLIENT_ADMIN",
  CLIENT_SUPPORT: "CLIENT_SUPPORT",
  CLIENT_FINANCE: "CLIENT_FINANCE",
  CLIENT_COMMERCIAL_TEAM: "CLIENT_COMMERCIAL_TEAM",
  CLIENT_DEV: "CLIENT_DEV",
  CLIENT_EXTERNAL_DEV: "CLIENT_EXTERNAL_DEV",
  PROPERTY_SUPPORT: "PROPERTY_SUPPORT",
  PROPERTY_FINANCE: "PROPERTY_FINANCE",
  PROPERTY_ANALYST: "PROPERTY_ANALYST",
  RESERVATION_AGENT: "RESERVATION_AGENT"
};

export function arrayDynamicSortByProperty(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substring(1);
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function sortCaseInsensitive(property) {
  return function(a, b) {
    return a[property].toLowerCase().localeCompare(b[property].toLowerCase());
  };
}

export const getRangedDatesArray = (startDate, dateRange) => {
  let datesArray = [];
  for (let i = 0; i < dateRange; i++) {
    const currentDate = addDays(parseISO(startDate), i);
    datesArray.push(format(currentDate, "yyyy-MM-dd"));
  }

  return datesArray;
};

export const oneHour = 60 * 60 * 1000;
export const oneDay = 24 * oneHour;
export const OneWeek = 7 * oneDay;

import update from "immutability-helper";
import {
  SET_SEARCH_PROPERTY_LOADING,
  UPDATE_SELECTED_PROPERTY,
  UPDATE_SEARCHED_PROPERTIES,
  SET_PROPERTY_NAME_TO_SEARCH,
  SET_RESERVATION_REPORT_LOADING,
  SET_RESERVATION_REPORT,
  SET_INTG_AUDIT_ARI_CONTENT,
  SET_HB_RES_INTG_AUDIT_ARI_CONTENT,
  SET_INTG_AUDIT_ARI_LOADING,
  TOGGLE_INTG_AUDIT_ARI_POPUP,
  TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP,
  SET_INTG_AUDIT_ARI_REQ_PARAMS,
  SET_SELECTED_PROPERTY,
  SET_PROPERTY_IMAGES,
  SET_PROPERTY_AMENITIES,
  UPDATE_REPORT_COLUMNS,
  SET_ALL_CLIENTS,
  SET_SOURCE_COMMISSION,
  SET_RESERVATION_REPORT_REQ_PARAMS,
  SET_ALL_SUPPLIERS,
  TOGGLE_BLACKLISTED_PROP_LIST,
  SET_BLACKLISTED_PROPS,
  SET_BLACKLISTED_PROPS_LOADING,
  SET_DIRECT_COMMISSIONS,
  SET_CLIENT_DETAILS,
  SET_A_CLIENT,
  SET_PROPERTY_TAXES,
  UPDATED_COLUMNS_HIDDEN,
  COLUMNS_FILTER_SELECTED,
  SET_REPORT_DASHBOARD_LOADING,
  REPORT_DASHBOARD,
  SET_ARI_REPORT_DASHBOARD_LOADING,
  ARI_REPORT_DASHBOARD,
  SET_RES_TOTALS_LOADING,
  RES_TOTAL,
  SET_ACTIONABLE_INVOICES_COUNT,
  SET_METADATA_NOTIFICATIONS_COUNT
} from "../actions/home";
import {
  SET_CACHE_RATES,
  SET_SEARCH_CITY_LOADING,
  SET_CACHE_RATES_LOADING,
  UPDATE_SELECTED_CITY,
  UPDATE_SEARCHED_CITIES,
  SET_CITY_NAME_TO_SEARCH
} from "../actions/cache";
import { find } from "lodash";
import { isASuperUserLoggedIn } from "../utils/secure-xs/secureSS";

const intgAuditARIContent = {
  ariBeforeReservation: {},
  ariAfterReservation: {}
};

const initialReservationReportReqParams = {
  actionableInvoices: 0,
  actionableMetadata: [],
  billingTypes: [],
  propertyName: "",
  propertyIds: [],
  chainCodes: [],
  brandCodes: [],
  lastName: "",
  selectedClientIds: [],
  firstName: "",
  confirmationNumber: "",
  clientConfirmationNumber: "",
  sourceConfirmationNumber: "",
  pmsConfirmationCode: "",
  companyCode: "",
  channels: [],
  crsIds: [],
  currencies: [],
  contractTypes: [],
  checkInFrom: "",
  checkInTo: "",
  checkOutFrom: "",
  checkOutTo: "",
  bookingFrom: "",
  bookingTo: "",
  cancelationFrom: "",
  cancelationTo: "",
  checkInFromDate: null,
  checkInToDate: null,
  checkOutFromDate: null,
  checkOutToDate: null,
  bookingFromDate: null,
  bookingToDate: null,
  cancelationFromDate: null,
  cancelationToDate: null,
  incldCancldReserv: false,
  includeorExcludeorOnlyCancelRes: 0,
  rateCodes: [],
  refundable: null,
  isCRSCodePending: false,
  isPMSCodePending: false,
  isCRSCXLIdPending: false,
  isDBPaid: false,
  isDBUnpaid: false,
  isRelocated: false,
  isSwapped: false,
};
const initialState = {
  selectedProperty:
    isASuperUserLoggedIn() && localStorage.getItem("selected_property")
      ? JSON.parse(localStorage.getItem("selected_property"))
      : {},
  propertyAmenities: [],
  propertyImages: [],
  properties: [],
  showIntgAuditARIPopup: false,
  showIntgAuditARIHBResPopup: false,
  reservationReports: [],
  intgAuditARIContent,
  hbResintgAuditARIContent: [],
  reservationReportColumns: null,
  allClients: [],
  allSuppliers: [],
  sourceCommission: [],
  reservationReportReqParams: initialReservationReportReqParams,
  initialReservationReportReqParams,
  searchPropertyBy: "",
  clientDetails: null,
  cacheRates: {},
  neighborhoodRegions: [],
  searchCityBy: "",
  columnsHidden: [],
  columnsFilterSelected: "All"
};

export default function onboarding(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SELECTED_PROPERTY:
      return update(state, { selectedProperty: { $set: action.property } });
    case SET_PROPERTY_NAME_TO_SEARCH:
      return update(state, {
        searchPropertyBy: { $set: action.payload.value },
        searchPropertyId: { $set: action.payload.id }
      });

    case SET_SEARCH_PROPERTY_LOADING:
      return update(state, {
        isSearchPropertyLoading: { $set: action.isSearchPropertyLoading }
      });

    case UPDATE_SEARCHED_PROPERTIES:
      return update(state, {
        properties: { $set: action.properties },
        isSearchPropertyLoading: { $set: action.isSearchPropertyLoading }
      });
    case UPDATE_SELECTED_CITY:
      return update(state, { selectedCity: { $set: action.cityName } });

    case SET_CITY_NAME_TO_SEARCH:
      return update(state, {
        searchCityBy: { $set: action.payload.value },
        searchCityId: { $set: action.payload.id }
      });

    case SET_SEARCH_CITY_LOADING:
      return update(state, {
        isSearchCityLoading: { $set: action.isSearchCityLoading }
      });

    case SET_CACHE_RATES_LOADING:
      return update(state, {
        isCacheRatesLoading: { $set: action.isCacheRatesLoading }
      });

    case UPDATE_SEARCHED_CITIES:
      return update(state, {
        neighborhoodRegions: { $set: action.neighborhoodRegions },
        isSearchCityLoading: { $set: action.isSearchCityLoading }
      });

    case SET_RESERVATION_REPORT_LOADING:
      return update(state, {
        isReservationReportLoading: { $set: action.isReservationReportLoading }
      });

    case SET_REPORT_DASHBOARD_LOADING:
      return update(state, {
        isReportDashboardLoading: { $set: action.isReportDashboardLoading }
      });

    case REPORT_DASHBOARD:
      return update(state, {
        reportDashboard: { $set: action.reportDashboard }
      });

    case SET_ACTIONABLE_INVOICES_COUNT:
      return update(state, {
        actionableInvoicesCount: { $set: action.actionableInvoicesCount }
      });

    case SET_METADATA_NOTIFICATIONS_COUNT:
      return update(state, {
        metadataNotificationsCount: { $set: action.metadataNotificationsCount }
      });

    case SET_ARI_REPORT_DASHBOARD_LOADING:
      return update(state, {
        isAriReportDashboardLoading: { $set: action.isAriReportDashboardLoading }
      });

    case ARI_REPORT_DASHBOARD:
      return update(state, {
        ariReportDashboard: { $set: action.ariReportDashboard }
      });

    case SET_RES_TOTALS_LOADING:
      return update(state, {
        isResTotalsLoading: { $set: action.isResTotalsLoading }
      });

    case RES_TOTAL:
      return update(state, {
        resTotals: { $set: action.resTotals }
      });

    case SET_RESERVATION_REPORT:
      return update(state, {
        reservationReports: { $set: action.reservationReports }
      });
    case SET_RESERVATION_REPORT_REQ_PARAMS:
      return update(state, {
        reservationReportReqParams: {
          $set: action.reservationReportReqParams || initialReservationReportReqParams
        }
      });

    case SET_INTG_AUDIT_ARI_LOADING:
      return update(state, {
        isIntgAuditARILoading: { $set: action.isIntgAuditARILoading }
      });
    case SET_INTG_AUDIT_ARI_CONTENT:
      return update(state, {
        intgAuditARIContent: {
          $set: action.intgAuditARIContent || intgAuditARIContent
        }
      });
    case SET_HB_RES_INTG_AUDIT_ARI_CONTENT:
      return update(state, {
        hbResintgAuditARIContent: {
          $set: action.hbResintgAuditARIContent || []
        }
      });

    case TOGGLE_INTG_AUDIT_ARI_POPUP:
      return update(state, {
        showIntgAuditARIPopup: { $set: action.showIntgAuditARIPopup }
      });
    case TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP:
      return update(state, {
        showIntgAuditARIHBResPopup: { $set: action.showIntgAuditARIHBResPopup }
      });
    case SET_INTG_AUDIT_ARI_REQ_PARAMS:
      return update(state, {
        intgAuditARIReqParams: { $set: action.intgAuditARIReqParams }
      });
    case SET_SELECTED_PROPERTY:
      return update(state, {
        selectedProperty: { $set: action.selectedProperty }
      });
    case SET_PROPERTY_TAXES:
      return update(state, {
        propertyTaxes: { $set: action.propertyTaxes }
      });
    case SET_PROPERTY_AMENITIES:
      return update(state, {
        propertyAmenities: { $set: action.propertyAmenities }
      });
    case SET_PROPERTY_IMAGES:
      return update(state, {
        propertyImages: { $set: action.propertyImages }
      });
    case SET_ALL_CLIENTS:
      return update(state, {
        allClients: { $set: action.allClients }
      });
    case SET_A_CLIENT:
      return update(state, {
        clientToEdit: { $set: action.clientToEdit }
      });
    case SET_ALL_SUPPLIERS:
      return update(state, {
        allSuppliers: { $set: action.allSuppliers }
      });

    case TOGGLE_BLACKLISTED_PROP_LIST:
      return update(state, {
        showBlacklistedProperties: { $set: !state.showBlacklistedProperties }
      });

    case SET_BLACKLISTED_PROPS:
      return update(state, {
        blacklistedProperties: { $set: action.blacklistedProperties }
      });
    case SET_DIRECT_COMMISSIONS:
      return update(state, {
        directCommissions: { $set: action.directCommissions }
      });

    case SET_BLACKLISTED_PROPS_LOADING:
      return update(state, {
        blacklistedPropertiesLoading: {
          $set: action.blacklistedPropertiesLoading
        }
      });

    case SET_SOURCE_COMMISSION:
      const allSuppliers = state.allSuppliers;
      const sourceCommission = allSuppliers.map(sup => {
        let foundCommission = find(action.sourceCommission, {
          supplierCode: sup.code
        });
        if (foundCommission) {
          foundCommission.supplierName = sup.supplierName;
        } else {
          foundCommission = {
            supplierCode: sup.code,
            supplierName: sup.supplierName,
            isNotInDb: true
          };
        }
        return foundCommission;
      });
      return update(state, {
        sourceCommission: { $set: sourceCommission }
      });

    case SET_CLIENT_DETAILS:
      return update(state, {
        clientDetails: { $set: action.clientDetails }
      });
    case UPDATE_REPORT_COLUMNS:
      return update(state, {
        reservationReportColumns: { $set: action.reservationReportColumns }
      });
    case SET_CACHE_RATES:
      return update(state, {
        cacheRates: { $set: action.cacheRates }
      });
    case UPDATED_COLUMNS_HIDDEN:
      return update(state, {
        columnsHidden: { $set: action.columnsHidden }
      });
    case COLUMNS_FILTER_SELECTED:
      return update(state, {
        columnsFilterSelected: { $set: action.columnsFilterSelected }
      });

    default:
      return state;
  }
}
